import AppConsts from '../Constants/AppConsts';
import { ownerTypes, phoneMask } from '../Constants/CommonConstants';
import { store } from '../index';
import { smoothScrollTop } from './commonFunctions';

const getAcres = (val) => {
  let acres = val;
  if (typeof val === 'number') {
    acres = `${val}`;
  }

  if (typeof acres === 'string') {
    acres = acres.startsWith('.') ? `0${acres}` : acres;
    return Number(acres);
  }
  return acres;
};

export function getDraftListingData() {
  const listingData = store.getState().propertyListing;
  const userId = listingData?.userId || store.getState().user.userDetails.userId;
  const userDetails = store.getState().user.userDetails;

  const propertyOwnersData = listingData.propertyOwners.map((owner) => {
    const ownerObj = {};
    Object.entries(owner).forEach(([key, val]) => {
      ownerObj[key] = val.value;
    });
    return ownerObj;
  });

  const { selectedAddress } = listingData;

  const data = {
    id: listingData.draftListingId || null,
    userId,
    listingData: {
      placeId: selectedAddress?.placeId || null,
      formatted: selectedAddress?.formatted || null,
      address1: selectedAddress?.address1 || null,
      address2: selectedAddress?.address2 || null,
      city: selectedAddress?.city || null,
      zip: selectedAddress?.zip || null,
      state: selectedAddress?.state || null,
      county: selectedAddress?.county || null,
      latitude: selectedAddress?.location?.lat || null,
      longitude: selectedAddress?.location?.lng || null,
      price: listingData?.propertyPrice || null,
      agentCommission: listingData?.agentCommission || null,
      propertyTypeId: listingData?.propertyTypeId || null,
      mlsCategoryId: listingData?.mlsCategoryId || null,
      bedRooms: listingData?.bedrooms || null,
      bathRooms: listingData?.bathrooms || null,
      halfBaths: listingData?.halfBaths,
      fullBaths: listingData?.fullBaths,
      averagePriceFrom: listingData?.averagePrice?.from ?? null,
      averagePriceTo: listingData?.averagePrice?.to ?? null,
      estimatePrice: listingData.estimatePrice,
      subdivision: listingData?.subdivision,
      floors: listingData?.floors,
      yearBuilt: listingData?.yearBuilt,
      architectureStyle: listingData?.architectureStyle,
      schoolDistrict: listingData?.schoolDistrict,
      primarySchool: listingData?.primarySchool,
      middleSchool: listingData?.middleSchool,
      highSchool: listingData?.highSchool,
      squares: listingData?.squareFeet ? Number(listingData?.squareFeet) : null,
      acres: getAcres(listingData.acres),
      planId: listingData?.selectedPlan?.productId || null,
      voucherCode: listingData?.redeemedVoucher?.voucherCode,
      userId,
      ownershipType: { type: listingData?.ownershipType || 1, ownersData: propertyOwnersData },
      description: listingData?.description || null,
      terms: listingData?.terms || null,
      draftFormsFields: listingData?.draftFormsFields || [],
      listingSeller: {
        firstName: listingData?.firstName || userDetails?.firstName || null,
        lastName: listingData?.lastName || userDetails?.lastName || null,
        phone: listingData?.phone || userDetails?.phoneNumber || null,
        email: listingData?.email || userDetails?.email || null,
      },
      titleCompanyId: listingData.titleCompanyId,
      titleCompanyName: listingData.titleCompanyName,
      titleCompanyEmail: listingData.titleCompanyEmail,
      titleCompanyPreference: listingData.titleCompanyPreference,
    },
    listingId: listingData?.listingId || null,
    step: listingData.step,
    companyType: listingData?.companyType,
    mlsId: listingData?.mlsId,
  };

  return data;
}

export const splitListingForms = (forms) => {
  const listingFormArr = [];

  const pArray = [];
  const countObj = [];
  forms.forEach((form) => {
    let formGroups = form.groups;
    let filterGroups = {};
    let filterSections = {};
    let formId = form.formId;
    let userFormId = form.userFormId;

    formGroups.forEach((group) => {
      if (group.formGroupId === 1) {
        let formSections = group.sections;
        let m = 0;
        formSections.forEach((sections) => {
          if (sections.priority) {
            m = Math.floor(sections.priority / 1000);

            if (!filterSections[m]) {
              filterSections[m] = [];
            }
            filterSections[m].push(sections);
          }
        });

        Object.keys(filterSections).forEach((key) => {
          let newGroup = {
            formGroupId: group.formGroupId,
            formGroupType: group.formGroupType,
            name: group.name,
            priority: filterSections[key][0].priority,
            sections: filterSections[key],
            formId: formId,
            userFormId: userFormId,
          };

          if (!filterGroups[key]) {
            filterGroups[key] = [];
          }
          filterGroups[key].push(newGroup);
        });
      } else {
        let p = 0;
        if (group.priority) {
          p = Math.floor(group.priority / 1000);
        } else {
          group.priority = form.priority;
        }
        group.formId = formId;
        group.userFormId = userFormId;
        if (!filterGroups[p]) {
          filterGroups[p] = [];
        }

        filterGroups[p].push(group);
      }
    });

    Object.entries(filterGroups).forEach(function ([key, value]) {
      Object.entries(value).forEach(function ([key1, value1]) {
        let formData = JSON.parse(JSON.stringify(form));
        if (formData.priority) {
          let p = Math.floor(value1.priority / 1000);
          if (!pArray[p]) {
            pArray[p] = [];
          }
          pArray[p].push(value1);
        }
      });
    });

    for (var key in pArray) {
      if (!countObj.includes(key)) {
        countObj.push(key);
        let formData = JSON.parse(JSON.stringify(form));
        formData.groups = pArray[key];
        if (formData.priority) {
          listingFormArr.push(formData);
        }
      }
    }
  });
  return listingFormArr;
};

export const getListingFomsFieldsObject = (formsArray) => {
  const fields = {};
  formsArray.forEach((form) => {
    form.groups.forEach((group) => {
      group.sections.forEach((section) => {
        section.fields.forEach((field) => {
          fields[field.formFieldId] = field;
        });
      });
    });
  });

  return fields;
};

export const convertSelectedAddress = (address) => {
  if (!address && Object.keys(address).length === 0) {
    return null;
  }
  const isPostalCode = address?.address_components.filter((c) => c.types.includes('postal_code')).length;
  const isCountyExist = address?.address_components.filter((c) =>
    c.types.includes('administrative_area_level_2')
  ).length;
  const isCityExist = address?.address_components.filter(
    (c) => c.types.includes('locality') || c.types.includes('sublocality') || c.types.includes('neighborhood')
  ).length;
  const isSubPremise = address?.address_components.filter((c) => c.types.includes('subpremise')).length;
  return {
    components: address.address_components,
    address1: `${address.address_components.filter((c) => c.types.includes('street_number') || c.types.includes('route'))[0]
        ?.long_name || ''
      } ${address.address_components.filter((c) => c.types.includes('route'))[0]?.short_name || ''}`,
    address2: address.unit || '',
    city:
      isCityExist > 0
        ? (
          address.address_components.filter((c) => c.types.includes('locality'))[0] ||
          address.address_components.filter((c) => c.types.includes('sublocality'))[0] ||
          address.address_components.filter((c) => c.types.includes('neighborhood'))[0]
        )?.long_name
        : '',
    county:
      isCountyExist > 0
        ? address.address_components
          .filter((c) => c.types.includes('administrative_area_level_2'))[0]
          ?.long_name.replace(/ County/g, '')
        : null,
    state: address.address_components.filter((c) => c.types.includes('administrative_area_level_1'))[0]?.short_name,
    zip:
      isPostalCode > 0
        ? address.address_components.filter((c) => c.types.includes('postal_code'))[0]?.long_name
        : !!isPostalCode,
    formatted: address?.formatted_address || '',
    id: address.id,
    placeId: address.place_id,
    name: address.name,
    url: address.url,
    location: {
      lat:
        typeof address.geometry.location.lat === 'function'
          ? address.geometry.location.lat()
          : address.geometry.location.lat,
      lng:
        typeof address.geometry.location.lng === 'function'
          ? address.geometry.location.lng()
          : address.geometry.location.lng,
      formatted: `${typeof address.geometry.location.lat === 'function'
          ? address.geometry.location.lat()
          : address.geometry.location.lat
        },${typeof address.geometry.location.lng === 'object'
          ? address.geometry.location.lng()
          : address.geometry.location.lng
        }`,
    },
    unit:
      isSubPremise > 0 ? address.address_components.filter((c) => c.types.includes('subpremise'))[0]?.long_name : '',
  };
};
export const gTag = (payload) => {
  if (!AppConsts.useGtm) {
    return;
  }
  window.dataLayer.push(payload);
};

export function scrollToFirstErroField(errors) {
  if (errors.length === 0) {
    return;
  }
  const firstErrorField = errors.reduce(
    (acc, item) => {
      const errorField = document.querySelector(`[name="${item}"]`);
      const fieldPosition = errorField?.getBoundingClientRect();
      
    if (!errorField || !fieldPosition) {
      return acc;
    }

      if (!acc.field || fieldPosition.top + window.scrollY < acc.top) {
        acc.field = errorField;
        acc.top = fieldPosition.top + window.scrollY;
      }
      return acc;
    },
    { top: null, field: null }
  );

  if (firstErrorField?.top) {
    window.scroll({ top: firstErrorField.top - 150, left: 0, behavior: 'smooth' });
  } else if (firstErrorField?.field?.scrollIntoView) {
    firstErrorField.field.scrollIntoView(true);
    } else {
    smoothScrollTop();
  }
}

export const getPropertyOwnerFields = (type, data = {}) =>
  type === ownerTypes.individual
    ? {
      ownerType: {
        value: ownerTypes.individual,
        error: null,
      },
      email: {
        value: data?.email ?? '',
        error: null,
      },
      name: {
        value: data?.name ?? '',
        error: null,
      },
      phone: {
        mask: phoneMask,
        value: data?.phone ?? '',
        error: null,
      },
    }
    : {
      ownerType: {
        value: ownerTypes.company,
        error: null,
      },
      companyName: {
        value: data?.companyName ?? '',
        error: null,
      },
      principalFirstName: {
        value: data?.principalFirstName ?? '',
        error: null,
      },
      principalLastName: {
        value: data?.principalLastName ?? '',
        error: null,
      },
      positionInCompany: {
        value: data?.positionInCompany ?? '',
        error: null,
      },
      email: {
        value: data?.email ?? '',
        error: null,
      },
      phone: {
        mask: phoneMask,
        value: data?.phone ?? '',
        error: null,
      },
    };
